<template>
  <VContainer class="px-0">
    <VRow dense>
      <VCol data-test="content-select-one-question">
        {{ meta.question }}
      </VCol>
    </VRow>
    <VRow
      v-for="(item, index) in meta.answers"
      :key="index"
      dense
    >
      <VCol
        cols="3"
        class="text-right tt-black--text text--lighten-2"
        :data-test="`content-select-one-title${index }`"
      >
        Ответ {{ index + 1 }}
      </VCol>
      <VCol>
        <div :data-test="`content-select-one-label${index}`">
          {{ item.label }}
          <VIcon
            v-if="item.is_right"
            color="success"
            size="14"
            :data-test="`content-select-one-icon${index}`"
          >
            fas fa-check-circle
          </VIcon>
        </div>
      </VCol>
    </VRow>
    <VRow
      v-if="meta.action.correct.length"
      dense
    >
      <VCol
        class=" tt-black--text text--lighten-2 text-right"
        cols="3"
        data-test="content-select-one-title-correct"
      >
        Правильно
      </VCol>
      <VCol data-test="content-select-one-correct">
        {{ meta.action.correct }}
      </VCol>
    </VRow>
    <VRow
      v-if="meta.action.incorrect.length"
      dense
    >
      <VCol
        class="tt-black--text text--lighten-2 text-right"
        cols="3"
        data-test="content-select-one-title-incorrect"
      >
        Неправильно
      </VCol>
      <VCol data-test="content-select-one-incorrect">
        {{ meta.action.incorrect }}
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>
export default {
  name: 'ContentSelectOne',
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  computed: {
    meta() {
      return JSON.parse(this.data.meta);
    },
  },
};
</script>

<style>
/* stylelint-ignore no-empty-source */
</style>
